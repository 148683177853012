import moment from 'moment';
import React from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Edit, Rightmark, TrashIcon } from '../../assets/images';
import {
  GET_FLEETUSER_DEV,
  GET_FLEETUSER_PROD,
} from '../../containers/FleetSetup/constants';
import CollapsableSection from '../CollapsableSection';
// import Help from '../Help/Help';
import './styles.scss';

class ContractorForm extends React.Component {
  constructor() {
    super();
    this.state = {
      userGroupInt: [],
      userGroup: '',
      selectedDate: new Date().toLocaleDateString('en-US'),
      notificationInterval: '',
      notificationEnabled: false,
      userGroupActive: false,
      editingIndex: null,
    };
  }

  componentDidMount() {
    const isProd = process.env.REACT_APP_ESS_API === 'production';
    if (!isProd) {
      const userGroup = GET_FLEETUSER_DEV;
      this.setState({ userGroupInt: userGroup });
    } else {
      const userGroupProd = GET_FLEETUSER_PROD;
      this.setState({ userGroupInt: userGroupProd });
    }
  }

  handleInputChange = (accessInputType, value, index = null) => {
    if (index === null) {
      this.setState({ [accessInputType]: value });
    } else {
      const { accessExpirationDetailList } = this.props.fleetFormValues;
      const updatedList = [...accessExpirationDetailList];
      updatedList[index] = { ...updatedList[index], [accessInputType]: value };
      this.props.onUpdateNotifications(updatedList);
    }
  };

  handleDateChange = (picker, index = null) => {
    const startDate = picker.startDate.format('MM/DD/YYYY');
    this.handleInputChange('selectedDate', startDate, index);
  };

  handleAddNotification = () => {
    const {
      userGroup,
      selectedDate,
      notificationInterval,
      notificationEnabled,
      userGroupActive,
    } = this.state;

    this.props.onAddNotification({
      userGroup,
      selectedDate,
      notificationInterval,
      notificationEnabled,
      userGroupActive,
      isEditing: false,
    });

    this.setState({
      userGroup: '',
      selectedDate: new Date().toLocaleDateString('en-US'),
      notificationInterval: '',
      notificationEnabled: false,
      userGroupActive: false,
    });
  };

  handleEditNotification = index => {
    const { accessExpirationDetailList } = this.props.fleetFormValues;
    const editingItem = accessExpirationDetailList[index];

    if (editingItem.isEditing) {
      this.props.onUpdateNotifications(accessExpirationDetailList);
    }

    const updatedList = [...accessExpirationDetailList];
    updatedList[index].isEditing = !editingItem.isEditing;
    this.props.onUpdateNotifications(updatedList);
  };

  isAddNotificationDisabled = () => {
    const { userGroup, selectedDate, notificationInterval } = this.state;
    return !(userGroup && selectedDate && notificationInterval);
  };

  customFormInput = (
    label,
    name,
    type,
    value,
    options = [],
    index = null,
    helpContent = '',
    disabled = false,
    showBorderRight = true
  ) => (
    <Form.Group
      as={Col}
      md={3}
      style={{ borderRight: `${showBorderRight ? '2px solid #e0e0e0' : ''}` }}>
      <Form.Label>
        {label}{' '}
        {/* {helpContent && <Help helpContent={helpContent} placement={'left'} />} */}
      </Form.Label>
      {type === 'select' ? (
        <Form.Control
          as="select"
          className="custom-select"
          name={name}
          onChange={e => this.handleInputChange(name, e.target.value, index)}
          value={value}
          disabled={disabled}>
          <option value="">Please select</option>
          {options.map((option, idx) => (
            <option value={option} key={idx}>
              {option}
            </option>
          ))}
        </Form.Control>
      ) : (
        <DateRangePicker
          onApply={(event, picker) => this.handleDateChange(picker, index)}
          initialSettings={{
            singleDatePicker: true,
            showDropdowns: true,
          }}>
          <Form.Control
            autoComplete="off"
            type="text"
            name={name}
            value={value && value}
            disabled={disabled}
            className="datepickervalue"
          />
        </DateRangePicker>
      )}
    </Form.Group>
  );

  render() {
    const { userGroupInt } = this.state;
    const { onDeleteNotification } = this.props;
    const { accessExpirationDetailList, isUpdate, isDraft } =
      this.props.fleetFormValues;
    const isDisabled = isUpdate && !isDraft;

    return (
      <div>
        <CollapsableSection title={'Access expiration data'}>
          <div
            id="contractor_status"
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <button
                type="button"
                style={{
                  fontSize: '14px',
                  height: '30px',
                  border: '1px solid rgba(200, 200, 200, 0.60)',
                  background: 'rgba(220, 220, 220, 0.60)',
                  boxShadow: '0px -1px 0px 0px #A0A0A0 inset',
                }}
                onClick={this.handleAddNotification}
                disabled={isDisabled || this.isAddNotificationDisabled()}>
                + Add
              </button>
            </div>
            <div
              style={{
                display: 'flex',
              }}>
              {this.customFormInput(
                'User Group',
                'userGroup',
                'select',
                this.state.userGroup,
                userGroupInt,
                null,
                'User Group',
                isDisabled,
                true
              )}
              {this.customFormInput(
                'Date',
                'selectedDate',
                'date',
                this.state.selectedDate,
                [],
                null,
                '',
                isDisabled,
                true
              )}

              {this.customFormInput(
                'Notification frequency',
                'notificationInterval',
                'select',
                this.state.notificationInterval,
                this.props.fleetSetupData.map(({ vl }) => vl),
                null,
                'Notification',
                isDisabled,
                false
              )}

              <div
                style={{
                  display: 'flex',
                  gap: '15px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '-10px',
                  borderRight: '2px solid #e0e0e0',
                }}>
                <Form.Check
                  type="switch"
                  label=""
                  name="notificationEnabled"
                  onChange={e =>
                    this.handleInputChange(
                      'notificationEnabled',
                      e.target.checked
                    )
                  }
                  checked={this.state.notificationEnabled}
                  disabled={isDisabled}
                  style={{ marginTop: '35px' }}
                />
                <span style={{ marginTop: '35px', width: '30px' }}>
                  {this.state.notificationEnabled ? 'ON' : 'OFF'}
                </span>
              </div>
              <Form.Group as={Col} md={3}>
                <div
                  style={{
                    height: '60px',
                    display: 'inline-flex',
                    alignItems: 'flex-end',
                  }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Form.Label>Activation</Form.Label>
                    <Form.Check
                      type="switch"
                      label=""
                      name="userGroupActive"
                      onChange={e =>
                        this.handleInputChange(
                          'userGroupActive',
                          e.target.checked
                        )
                      }
                      checked={this.state.userGroupActive}
                      disabled={isDisabled}
                    />
                  </div>
                  <span>
                    {this.state.userGroupActive ? 'Active' : 'Inactive'}
                  </span>
                </div>
              </Form.Group>
            </div>
          </div>

          <div>
            {accessExpirationDetailList?.map(
              (accessExpirationDetail, index) => (
                <li key={index} style={{ listStyle: 'none' }}>
                  <div id="contractor_status" style={{ display: 'flex' }}>
                    {this.customFormInput(
                      '',
                      'userGroup',
                      'select',
                      accessExpirationDetail.userGroup,
                      userGroupInt,
                      index,
                      '',
                      !accessExpirationDetail.isEditing,
                      true
                    )}
                    {this.customFormInput(
                      '',
                      'selectedDate',
                      'date',
                      moment(accessExpirationDetail.selectedDate).format(
                        'MM/DD/YYYY'
                      ),
                      [],
                      index,
                      '',
                      !accessExpirationDetail.isEditing,
                      true
                    )}
                    {this.customFormInput(
                      '',
                      'notificationInterval',
                      'select',
                      accessExpirationDetail.notificationInterval,
                      this.props.fleetSetupData.map(({ vl }) => vl),
                      index,
                      '',
                      !accessExpirationDetail.isEditing,
                      false
                    )}
                    <div
                      style={{
                        display: 'flex',
                        gap: '15px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '-10px',
                        borderRight: '2px solid #e0e0e0',
                      }}>
                      <Form.Check
                        type="switch"
                        label=""
                        name="notificationEnabled"
                        onChange={e =>
                          this.handleInputChange(
                            'notificationEnabled',
                            e.target.checked,
                            index
                          )
                        }
                        checked={accessExpirationDetail.notificationEnabled}
                        disabled={!accessExpirationDetail.isEditing}
                        style={{ marginTop: '25px' }}
                      />
                      <span style={{ marginTop: '25px', width: '30px' }}>
                        {accessExpirationDetail.notificationEnabled
                          ? 'ON'
                          : 'OFF'}
                      </span>
                    </div>
                    <Form.Group as={Col} md={1}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginTop: '25px',
                          gap: '10px',
                        }}>
                        <span
                          style={{
                            display: 'flex',
                            gap: '10px',
                            width: '100px',
                          }}>
                          <Form.Check
                            type="switch"
                            label=""
                            name="userGroupActive"
                            onChange={e =>
                              this.handleInputChange(
                                'userGroupActive',
                                e.target.checked,
                                index
                              )
                            }
                            checked={accessExpirationDetail.userGroupActive}
                            disabled={!accessExpirationDetail.isEditing}
                          />
                          <span>
                            {accessExpirationDetail.userGroupActive
                              ? 'Active'
                              : 'Inactive'}
                          </span>
                        </span>
                      </div>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md={2}
                      style={{ borderRight: '2px solid #e0e0e0' }}>
                      <span
                        style={{
                          display: 'flex',
                          gap: '12px',
                          marginTop: '25px',
                          marginLeft: '2px',
                        }}>
                        <button
                          type="button"
                          style={{
                            background: 'transparent',
                            width: '5px',
                            paddingLeft: '15px',
                          }}
                          onClick={() => this.handleEditNotification(index)}>
                          {accessExpirationDetail.isEditing ? (
                            <Rightmark />
                          ) : (
                            <Edit />
                          )}
                        </button>
                        <button
                          type="button"
                          style={{
                            background: 'transparent',
                            maxWidth: '5px',
                          }}
                          onClick={() => onDeleteNotification(index)}>
                          <TrashIcon />
                        </button>
                      </span>
                    </Form.Group>
                  </div>
                </li>
              )
            )}
          </div>
        </CollapsableSection>
      </div>
    );
  }
}

export default ContractorForm;
