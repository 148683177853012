import React, { useEffect, useState } from 'react';
import { ArrowRightLine, UnauthorizedIcon } from '../../assets/images';
import './styles.scss';
function Unauthorized() {
  const assetGroups = sessionStorage.getItem('assetGroups')
    ? JSON.parse(sessionStorage.getItem('assetGroups'))
    : [];
  const fleetName = sessionStorage.getItem('selectedFleet')
    ? sessionStorage.getItem('selectedFleet')
    : '';
  const [isFleetExpired, setIsFleetExpired] = useState(false);
  const setIsFleetsExpired = () => {
    const isExpired = assetGroups.map(item => {
      if (item.fleetName === fleetName && item.isExpired) {
        return true;
      } else {
        return false;
      }
    });
    const isFleetInValid = isExpired.includes(true);
    setIsFleetExpired(isFleetInValid);
  };
  useEffect(() => {
    setIsFleetsExpired();
  }, []);
  return (
    <div className="unauth_page">
      <div className="unauth_content">
        <UnauthorizedIcon alt="ABB" />
        {!isFleetExpired && (
          <>
            <div className="text-center">
              Sorry! You don’t have the required premissions or privilages to
              access the page. <br />
              Please contact portal administrator for access grants.
            </div>
            <button
              className="buttonBack"
              onClick={() => window.location.replace('/Dashboard')}>
              <ArrowRightLine
                style={{ transform: 'rotate(180deg)', marginRight: '10px' }}
              />
              Back to Dashboard
            </button>
          </>
        )}
        {isFleetExpired && (
          <div>
            <div className="text-center">
              Sorry! Your access has expired. <br />
              Please contact ABB to renew your access.
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Unauthorized;
