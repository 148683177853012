import Moment from 'moment';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { connect } from 'react-redux';
import {
  AvailableStatusIcon,
  AvailableUnknownIcon,
  NotAvailableStatusIcon,
  NotReliableAvailableStatusIcon,
  ReliableAvailableStatusIcon,
  ReliableUnknownStatusIcon,
  StressAvailableStatusIcon,
  StressNotAvailableStatusIcon,
  StressUnknownStatusIcon,
  Warning,
} from '../../assets/images';
import {
  setGlossaryStateOpen,
  setGlossaryTag,
} from '../../containers/DashBoard/actions';
import { checkUserEntitlements } from '../../userConfig';
import BatteryConditionInfo from '../BatteryConditionInfo';
import { IconInfo } from '../Icons';
import PieGraph from '../PieGraph';
import StockGraph from '../StockGraph';
import Table from '../Table';
import UtilizationThresholdInfo from '../UtilizationThresholdInfo';
import './styles.scss';

Moment.locale('en');
const columns = [
  {
    Header: 'TYPE',
    accessor: 'typ',
    Cell: props => {
      if (props.value === 'Ready') {
        return <img src={require('../../assets/images/info.svg').default} />;
      } else if (props.value === 'Warning') {
        return <Warning />;
      } else {
        return <img src={require('../../assets/images/info.svg').default} />;
      }
    },
  },
  {
    Header: 'EVENT NAME',
    accessor: 'nm',
  },
  {
    Header: 'CODE',
    accessor: 'cd',
  },
  {
    Header: 'PRODUCT',
    accessor: 'pd',
  },
  {
    Header: 'TIME',
    accessor: 'dt',
    Cell: ({ row }) =>
      Moment.utc(row.original.dt).format('HH:mm:ss \xa0DD.MM.YYYY') +
      ' ' +
      row.original.tz,
  },
  {
    Header: 'STATUS',
    accessor: 'sts',
  },
  {
    Header: 'DESCRIPTION',
    accessor: 'msg',
  },
];

class ProductStatusTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      colChartData: this.props.batteryRelData[0],
      filteredcolChartData: this.props.batteryRelData[0],
      isRelactive: false,
      colChartStrData: this.props.batteryStrData[0],
      filteredcolChartStrData: this.props.batteryStrData[0],
      isStractive: false,
    };
  }

  openGlossary = () => {
    this.props.setGlossaryStateOpen(true);

    const selectedTab = this.props.selectedOverviewTab;
    if (selectedTab.includes('avail'))
      this.props.setGlossaryTag('Product Availability');
    else if (selectedTab.includes('reli'))
      this.props.setGlossaryTag('Product Reliability');
    else if (selectedTab.includes('util'))
      this.props.setGlossaryTag('Product Utilization');
    else if (selectedTab.includes('binfo'))
      this.props.setGlossaryTag('Product Overview Basic Info');
    else this.props.setGlossaryTag('');
  };

  pieChartSelected = (chartId, flag, status) => {
    let colChartDataCopy;
    let colChartStrDataCopy;
    if (chartId === 'REL' && flag === true) {
      colChartDataCopy = this.state.colChartData.col.filter(
        item => item.name === status
      );
      this.setState(
        {
          filteredcolChartData: {
            ...this.state.filteredcolChartData,
            col: colChartDataCopy,
          },
          isRelactive: flag,
        },
        () => {
          setTimeout(() => {
            this.setState({
              isRelactive: false,
            });
          }, 0);
        }
      );
    } else if (chartId === 'REL' && flag === false) {
      this.setState(
        {
          filteredcolChartData: this.state.colChartData,
          isRelactive: true,
        },
        () => {
          setTimeout(() => {
            this.setState({
              isRelactive: false,
            });
          }, 0);
        }
      );
    } else if (chartId === 'STR' && flag === true) {
      colChartStrDataCopy = this.state.colChartStrData.col.filter(
        item => item.name === status
      );
      this.setState(
        {
          filteredcolChartStrData: {
            ...this.state.filteredcolChartStrData,
            col: colChartStrDataCopy,
          },
          isStractive: flag,
        },
        () => {
          setTimeout(() => {
            this.setState({
              isStractive: false,
            });
          }, 0);
        }
      );
    } else if (chartId === 'STR' && flag === false) {
      this.setState(
        {
          filteredcolChartStrData: this.state.colChartStrData,
          isStractive: true,
        },
        () => {
          setTimeout(() => {
            this.setState({
              isStractive: false,
            });
          }, 0);
        }
      );
    }
  };

  render() {
    return (
      <Tabs
        id="prodststabs"
        activeKey={this.props.selectedOverviewTab}
        onSelect={k => this.props.onProductOverviewTabClick(k)}>
        {checkUserEntitlements(
          'ESS:Product-Overview-Basicinfo',
          'Overview-vehicleprod-view',
          'binfo'
        ) && (
          <Tab
            eventKey="binfo"
            title={
              <span className="tabIcon">
                {/* <img src={require("../../assets/images/binfo.svg")} /> */}

                <span className="tabtitle" id="binfo">
                  Basic info
                </span>
              </span>
            }>
            {this.props.batteryOverviewData.length > 0 ? (
              <Row>
                <Col md={12} className="noLeftpadding noRightpadding">
                  <button
                    data-intro="glossary-icon"
                    title="Glossary Icon"
                    className="infoIcon"
                    onClick={this.openGlossary}>
                    <IconInfo color={'black'} />
                  </button>
                  <BatteryConditionInfo
                    batteryOverviewData={this.props.batteryOverviewData}
                    userConfig={this.props.userConfig}
                  />
                </Col>
              </Row>
            ) : (
              <Col md={12} className="addMarginTop nodatafound">
                <div> Sorry...</div>
                <div className="addMarginTop">No data found !</div>
              </Col>
            )}
          </Tab>
        )}
        {checkUserEntitlements(
          'ESS:Product-Overview-Availability',
          'Overview-vehicleprod-view',
          'avail'
        ) && (
          <Tab
            eventKey="avail"
            title={
              <span className="tabIcon">
                {this.props.batteryOverallConditionData[0].stx === 'Ready' ? (
                  <AvailableStatusIcon />
                ) : this.props.batteryOverallConditionData[0].stx ===
                  'Warning' ? (
                  <NotAvailableStatusIcon />
                ) : (
                  <AvailableUnknownIcon />
                )}

                <span className="tabtitle"> Availability</span>
              </span>
            }>
            <div className="availcon">
              <>
                <div className="stsinfo">
                  <span className="txt">
                    {this.props.batteryOverallConditionData[0].stx ===
                    'Ready' ? (
                      <span id="ok"> Normal operation </span>
                    ) : this.props.batteryOverallConditionData[0].stx ===
                      'Warning' ? (
                      <span id="notok"> Error </span>
                    ) : (
                      <span id="unknown"> Unknown </span>
                    )}
                  </span>
                  <span className="util-info-smalltxt">
                    {this.props.batteryOverallConditionData[0].stx === 'Ready'
                      ? 'Last status signal received is in operating mode (within last 48 hours)'
                      : this.props.batteryOverallConditionData[0].stx ===
                          'Warning'
                        ? 'Last status signal received is in error mode (within last 48 hours)'
                        : 'No status signal received (within last 48 hours)'}
                  </span>
                  <button
                    data-intro="glossary-icon"
                    title="Glossary Icon"
                    className="infoIcon"
                    onClick={this.openGlossary}>
                    <IconInfo color={'black'} />
                  </button>
                </div>
                <Table
                  columns={columns}
                  data={[
                    {
                      typ: this.props.batteryOverallConditionData[0].stx,
                      msg: 'N/A',
                      dt: this.props.batteryOverallConditionData[0].ts,
                      nm: 'Availability',
                      cd: '0X001',
                      sts: 'N/A',
                      pd:
                        sessionStorage.getItem('selectedFleet') ===
                        'ABB ESS Demo Fleet'
                          ? `ESS_HP_2233_${this.props.batteryOverallConditionData[0].bps}`
                          : `${this.props.batteryOverallConditionData[0].dn}`,
                      tz: this.props.batteryOverallConditionData[0].tz,
                    },
                  ]}
                />
              </>
            </div>
          </Tab>
        )}
        {checkUserEntitlements(
          'ESS:Product-Overview-Reliability',
          'Overview-vehicleprod-view',
          'reli'
        ) && (
          <Tab
            eventKey="reli"
            title={
              <span className="tabIcon" data-intro="rel-tab-title">
                {this.props.batteryRelData.length > 0 ? (
                  this.props.batteryRelData[0].sts === 'Reliable' ? (
                    <ReliableAvailableStatusIcon />
                  ) : this.props.batteryRelData[0].sts === 'Not reliable' ? (
                    <NotReliableAvailableStatusIcon />
                  ) : (
                    <ReliableUnknownStatusIcon />
                  )
                ) : (
                  <ReliableUnknownStatusIcon />
                )}
                <span className="tabtitle"> Reliability</span>
              </span>
            }>
            {this.props.batteryRelData.length > 0 ? (
              <Row>
                <Col md={12} className="noLeftpadding noRightpadding">
                  <div className="stsinfo">
                    <span className="txt">
                      {this.props.batteryRelData[0].sts === 'Reliable' ? (
                        <span id="ok"> Reliable </span>
                      ) : this.props.batteryRelData[0].sts ===
                        'Not reliable' ? (
                        <span id="notok"> Not reliable </span>
                      ) : (
                        <span id="unknown"> Unknown </span>
                      )}
                    </span>
                    <span className="util-info-smalltxt">
                      Accumulated availability status over &nbsp;
                      {sessionStorage.getItem('selectedFleet') ===
                      'ABB ESS Demo Fleet'
                        ? '30 days '
                        : 'the last 30 days '}{' '}
                      (Click on the section of stacked bar chart for details)
                    </span>
                    <button
                      data-intro="glossary-icon"
                      title="Glossary Icon"
                      className="infoIcon"
                      onClick={this.openGlossary}>
                      <IconInfo color={'black'} />
                    </button>
                    <br />
                    <span className="util-info-txt formulatext">
                      {this.props.batteryRelData[0].sts === 'Reliable' ? (
                        <span>
                          <span>Hours in error state</span>
                          <span className="dividesymbol"> &#247;</span>
                          <span> total operation time</span>{' '}
                          <span>
                            {' '}
                            &lt; {this.props.batteryRelData[0].ctv} %
                          </span>
                        </span>
                      ) : this.props.batteryRelData[0].sts ===
                        'Not reliable' ? (
                        <span>
                          <span>Hours in error state</span>
                          <span className="dividesymbol"> &#247;</span>
                          <span> total operation time</span>{' '}
                          <span> &gt;{this.props.batteryRelData[0].ctv} %</span>
                        </span>
                      ) : (
                        ''
                      )}
                    </span>{' '}
                  </div>
                </Col>
                <Col
                  md={4}
                  className="noLeftpadding noRightpadding"
                  data-intro="pie_graph_rel">
                  <PieGraph
                    chartType="BATT_REL_PIE_TYPE"
                    data={this.props.batteryRelData[0].pie}
                    pieChartSelected={this.pieChartSelected}
                  />
                </Col>
                <Col
                  md={8}
                  className="noLeftpadding noRightpadding"
                  data-intro="stock_graph_rel">
                  {this.state.isRelactive === true ? (
                    <div className="filtering">Filtering...</div>
                  ) : (
                    <StockGraph
                      chartType="BATT_REL_COL_TYPE"
                      data={this.state.filteredcolChartData}
                      redirectToTimetrend={this.props.redirectToTimetrend}
                    />
                  )}
                </Col>
              </Row>
            ) : (
              <Col md={12} className="addMarginTop nodatafound">
                <div> Sorry...</div>
                <div className="addMarginTop">No data found !</div>
              </Col>
            )}
          </Tab>
        )}
        {checkUserEntitlements(
          'ESS:Product-Overview-Utilization',
          'Overview-vehicleprod-view',
          'util'
        ) && (
          <Tab
            eventKey="util"
            title={
              <div data-intro="util-tab-title">
                <span className="tabIcon">
                  {this.props.batteryStrData.length > 0 ? (
                    this.props.batteryStrData[0].sts === 'Normal' ? (
                      <StressAvailableStatusIcon />
                    ) : this.props.batteryStrData[0].sts === 'Above normal' ? (
                      <StressNotAvailableStatusIcon />
                    ) : (
                      <StressUnknownStatusIcon />
                    )
                  ) : (
                    <StressUnknownStatusIcon />
                  )}
                  <span className="tabtitle">Utilization</span>
                </span>
              </div>
            }>
            {this.props.batteryStrData.length > 0 ? (
              <Row data-intro="utilization_panel">
                <Col md={12} className="noLeftpadding noRightpadding">
                  <div className="stsinfo">
                    <span className="txt">
                      {this.props.batteryStrData[0].sts === 'Normal' ? (
                        <span id="ok"> Normal utilization </span>
                      ) : this.props.batteryStrData[0].sts ===
                        'Above normal' ? (
                        <span id="notok"> Above normal utilization </span>
                      ) : (
                        <span id="unknown"> Unknown </span>
                      )}
                    </span>
                    <button
                      data-intro="glossary-icon"
                      title="Glossary Icon"
                      className="infoIcon"
                      onClick={this.openGlossary}>
                      <IconInfo color={'black'} />
                    </button>
                    <span className="util-info-smalltxt">
                      {sessionStorage.getItem('selectedFleet') ===
                      'ABB ESS Demo Fleet'
                        ? ' 30 days '
                        : ' last 30 days '}
                      (Click on the section of stacked bar chart for details){' '}
                      <br />
                    </span>

                    {this.props.batteryStrData[0].sts === 'Normal' ? (
                      <Row className="util-info-txt formulatext">
                        <Col md={12} className="noLeftpadding ">
                          <span>Hours above normal utilization</span>
                          <span className="dividesymbol"> &#247;</span>
                          <span> total operation time</span>{' '}
                          <span>
                            &lt; {this.props.batteryStrData[0].ctv.ctv} %
                          </span>
                        </Col>
                        {/* <Col
                            md={3}
                            className="noLeftpadding noRightpadding d-flex align-items-center"
                          >
                            &lt; 25%
                          </Col> */}
                      </Row>
                    ) : this.props.batteryStrData[0].sts === 'Above normal' ? (
                      <Row className="util-info-txt formulatext">
                        <Col md={12} className="noLeftpadding ">
                          <span>Hours above normal utilization</span>
                          <span className="dividesymbol"> &#247;</span>
                          <span> total operation time</span>
                          <span>
                            {' '}
                            &gt; {this.props.batteryStrData[0].ctv.ctv} %
                          </span>
                        </Col>
                        {/* <Col
                            md={3}
                            className="noLeftpadding noRightpadding d-flex align-items-center"
                          >
                            &gt; 25%
                          </Col> */}
                      </Row>
                    ) : (
                      <span className="util-info-txt">
                        {' '}
                        Utilization indicates unknown when the relavent data is
                        not available.
                        <br />{' '}
                      </span>
                    )}
                  </div>
                </Col>
                <Col
                  md={4}
                  className="noLeftpadding noRightpadding"
                  data-intro="pie_graph">
                  <PieGraph
                    chartType="BATT_STR_PIE_TYPE"
                    data={this.props.batteryStrData[0].pie}
                    pieChartSelected={this.pieChartSelected}
                  />
                </Col>
                <Col
                  md={8}
                  className="noLeftpadding noRightpadding"
                  data-intro="stock_graph">
                  {this.state.isStractive === true ? (
                    <div className="filtering">Filtering...</div>
                  ) : (
                    <StockGraph
                      chartType="BATT_STR_COL_TYPE"
                      data={this.state.filteredcolChartStrData}
                      redirectToTimetrend={this.props.redirectToTimetrend}
                    />
                  )}
                </Col>
                <div className="threstable" data-intro="thresholds_table">
                  <UtilizationThresholdInfo
                    stressData={this.props.batteryStrData[0].ctv}
                  />
                </div>
              </Row>
            ) : (
              <Col md={12} className="addMarginTop nodatafound">
                <div> Sorry...</div>
                <div className="addMarginTop">No data found !</div>
              </Col>
            )}
          </Tab>
        )}
      </Tabs>
    );
  }
}
const mapDispatchToProps = {
  setGlossaryStateOpen,
  setGlossaryTag,
};
export default connect(null, mapDispatchToProps)(ProductStatusTabs);
