import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import api from '../../utils/api';
import { handleError } from '../../utils/api-utils';
import {
  checkUserFailure,
  checkUserSucess,
  fetchAllFleetListFailure,
  fetchAllFleetListSucess,
  fetchFilterListFailure,
  fetchFilterListSucess,
  fetchFleetListFailure,
  fetchFleetListSucess,
  fetchMapDataFailure,
  fetchMapDataSucess,
  fetchMonitoredCountFailure,
  fetchMonitoredCountSucess,
  setGlossaryStateOpen,
  setGlossaryTag,
} from './actions';
import {
  CHECK_USER,
  FETCH_ALL_FLEET_LIST,
  FETCH_FILTER_LIST,
  FETCH_FLEET_LIST,
  FETCH_GLOSSARY_TAG,
  FETCH_MAP_DATA,
  FETCH_MONITORED_COUNT,
  TOGGLE_GLOSSARY_STATE,
} from './constants';

export function* fetchFleetSaga() {
  yield takeLatest(FETCH_FLEET_LIST, fetachingFleetSaga);
}
export function* fetachingFleetSaga(action) {
  try {
    const { site, customer, fleet, ok, trip, unknown } = action.payload;
    const response = yield call(
      api.fetchFleetListData,
      site,
      customer,
      fleet,
      ok,
      trip,
      unknown
    );
    const FleetList = response.dc; // .data_Constants;
    // FleetList.push(FleetListItems); // temp to add mock data

    yield put(fetchFleetListSucess(FleetList));
    // }
  } catch (error) {
    // yield put(fetchFleetListFailure(error));
    yield put(handleError(error, fetchFleetListFailure));
  }
}
export function* fetchAllFleetSaga() {
  yield takeLatest(FETCH_ALL_FLEET_LIST, fetchingAllFleetSaga);
}
export function* fetchingAllFleetSaga(action) {
  try {
    const { ok, trip, unknown } = action.payload;
    const response = yield call(
      api.fetchFleetListData,
      'All',
      'All',
      'All',
      ok,
      trip,
      unknown
    );
    const FleetList = response.dc;
    yield put(fetchAllFleetListSucess(FleetList));
  } catch (error) {
    yield put(handleError(error, fetchAllFleetListFailure));
  }
}

export function* checkUserSaga() {
  yield takeLatest(CHECK_USER, checkingUserSaga);
}

export function* checkingUserSaga(action) {
  try {
    const { email } = action.payload;
    const response = yield call(api.checkUser, email);
    const userData = response.data; // .data_Constants;
    yield put(checkUserSucess(userData));
  } catch (error) {
    yield put(handleError(error, checkUserFailure));
  }
}

export function* fetachMapDataSaga() {
  yield takeLatest(FETCH_MAP_DATA, fetachingMapDataSaga);
}
export function* fetachingMapDataSaga(action) {
  try {
    const response = yield call(api.fetchMapDataList);
    const MapData = response;

    // dispatch a success action to the store with the new dog
    yield put(fetchMapDataSucess(MapData));
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put(handleError(error, fetchMapDataFailure));
  }
}

export function* fetchFilterSaga() {
  yield takeLatest(FETCH_FILTER_LIST, fetchingFilterSaga);
}
export function* fetchingFilterSaga(action) {
  try {
    const response = yield call(api.fetchFilterListData);
    const FilterList = response.dc; // .data_Constants;
    yield put(fetchFilterListSucess(FilterList));
  } catch (error) {
    // yield put(fetchFleetListFailure(error));
    yield put(handleError(error, fetchFilterListFailure));
  }
}

export function* fetchMonitoredCountSaga() {
  yield takeLatest(FETCH_MONITORED_COUNT, fetchingMonitoringCountSaga);
}
export function* fetchingMonitoringCountSaga(action) {
  try {
    const response = yield call(api.fetchMonitoredCountData);
    const emptyData = [{ fc: 0, sc: 0, vc: 0 }];
    const monitoredCount = response.dc.length > 0 ? response.dc : emptyData; // .data_Constants;
    yield put(fetchMonitoredCountSucess(monitoredCount));
  } catch (error) {
    // yield put(fetchFleetListFailure(error));
    yield put(handleError(error, fetchMonitoredCountFailure));
  }
}

export function* handleGlossaryStateSaga(action) {
  yield put(setGlossaryStateOpen(action.payload.isOpen));
}
export function* setGlossaryStateOpenSaga() {
  yield takeEvery(TOGGLE_GLOSSARY_STATE, handleGlossaryStateSaga);
}

export function* handleGlossaryTagSaga(action) {
  yield put(setGlossaryTag(action.payload.tagDetails));
}
export function* setGlossaryTagSaga() {
  yield takeEvery(FETCH_GLOSSARY_TAG, handleGlossaryTagSaga);
}
